










































































































































































































import Vue from 'vue';

export default Vue.extend({
  props: {
    businessId: {
      type: String,
      required: true,
    },
    currentPricing: {
      type: String as () => string,
      required: true,
    },
    pricingChangedAt: {
      type: Number,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      error: null,
      loading: false,

      withFlex: false,
      withPlus: false,
    };
  },
  computed: {
    selectedPricing(): string | null {
      const addons = [];

      if (this.withFlex) addons.push('flex');
      if (this.withPlus) addons.push('plus');

      let pricing = 'v2.base';

      if (addons.length > 0) {
        pricing += '+';
        pricing += addons.join('+');
      }

      return pricing;
    },
    selectedPricingFee(): number | null {
      let feePercent = 15;

      if (this.withFlex) feePercent += 5;
      if (this.withPlus) feePercent += 5;

      return feePercent;
    },
  },
  watch: {
    currentPricing: {
      immediate: true,
      handler(to: string) {
        this.setPricing(to);
      },
    },
    '$route.query.flex': {
      immediate: true,
      handler(to: string) {
        if (to) this.withFlex = true;
      },
    },
    '$route.query.plus': {
      immediate: true,
      handler(to: string) {
        if (to) this.withPlus = true;
      },
    },
  },
  methods: {
    setPricing(to: string) {
      this.withFlex = to.includes('flex');
      this.withPlus = to.includes('plus');
    },
    cancel() {
      this.setPricing(this.currentPricing);
    },
    async save() {
      this.loading = true;

      await this.$store.dispatch('business/ChangePricing', {
        BUSINESS_ID: this.businessId,
        form: {
          pricing_id: this.selectedPricing,
        },
      }).catch((e) => { this.error = e; });

      this.loading = false;
    },
  },
});
