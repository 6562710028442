
























import Vue, { VueConstructor } from 'vue';
import { mapGetters } from 'vuex';
import { ApiResource } from '@/types';
import PricingV2Form from '@/components/dashboard/PricingV2Form.vue';

interface VuexBindings {
  detect: (idOrVanity: string) => ApiResource.Business,
}

export default (Vue as VueConstructor<Vue & VuexBindings>).extend({
  components: {
    PricingV2Form,
  },
  props: {
    businessVanity: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      detect: 'business/Detect',
    }),
    BUSINESS(): ApiResource.Business {
      return this.detect(this.businessVanity);
    },
  },
});
